// Add div to mount to
const id = "consent-manager";
const div = document.createElement("div");
div.id = id;

document.body.appendChild(div);

// Add Styles
var css = `
    #consent-manager {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
    `,
	head = document.head || document.getElementsByTagName("head")[0],
	style = document.createElement("style");

head.appendChild(style);

style.type = "text/css";
if (style.styleSheet) {
	// This is required for IE8 and below.
	style.styleSheet.cssText = css;
} else {
	style.appendChild(document.createTextNode(css));
}

// Set config
window.consentManagerConfig = function (exports) {
	var React = exports.React;

	var bannerContent = React.createElement(
		"div",
		null,
		"We use cookies to improve your experience and give you personalized content. Do you agree to our ",
		" ",
		React.createElement(
			"a",
			{
				href: "https://buzzsumo.com/legal/cookie-policy/",
				style: { color: "#239edb" },
				target: "_blank",
			},
			"cookie policy?",
		),
	);
	return {
		container: `#${id}`,
		writeKey: process.env.SEGMENT_WRITE_KEY,
		bannerContent: bannerContent,
		bannerSubContent: "No, take me to settings",
		preferencesDialogTitle: "Website Data Collection",
		preferencesDialogContent: "We use data collected by cookies and JavaScript libraries.",
		cancelDialogTitle: "🤔Are you sure you want to cancel?",
		cancelDialogContent: "Your preferences have not been saved.",
	};
};

if (!window.EXPORT) {
	var script = document.createElement("script");
	script.type = "text/javascript";
	script.src = "https://app.buzzsumo.com/consent-manager.js";
	head.appendChild(script);
}
